<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.wolt_configs')">
            <div>
                <b-form-group :label="$t('table_fields.business_unit')">
                    <v-select class="dashboard-select" :placeholder="$t('config.business_units.choose_business_area')" v-model="selected_business_unit" label="name" :options="allowedBusinessUnits" :clearable="false" :reduce="ele => ele.id" @option:selected="businessUnitChanged"/>
                </b-form-group>
            </div>
        </b-card>
        <validation-observer ref="validationWoltData">
            <b-card class="my-1" no-body v-if="selected_business_unit">
                <b-card-header
                    class="d-flex justify-content-between align-items-center"
                    @click="isDataOpen = !isDataOpen"
                    aria-expanded="true"
                    aria-controls="accordion-1"
                >
                    <h4 class="card-title">
                        {{$t('wolt.data')}}
                        <fa v-if="isDataInvalid" icon="exclamation-circle" class="text-danger ml-1"/>
                    </h4>
                    <fa
                        :icon="isDataOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                        class="ml-auto cursor-pointer"
                    ></fa>
                </b-card-header>
                <b-collapse id="accordion-1" v-model="isDataOpen" role="tabpanel" accordion="my-accordion">
                    <b-card-body>
                        <b-form-group :label="$t('wolt.authentication_key')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.authentication_key')"
                                rules="required"
                            >
                                <b-form-input v-model="woltConfigs.wolt_auth_key"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group :label="$t('wolt.api_key')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.api_key')"
                                rules="required"
                            >
                                <b-form-input v-model="woltConfigs.wolt_api_key"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('wolt.venue_id')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.venue_id')"
                                rules="required"
                            >
                                <b-form-input v-model="woltConfigs.wolt_venue_id"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group :label="$t('wolt.map_payment')">
                            <v-select :options="paymentTypes" :clearable="false" label="name" :reduce="ele => ele.id" v-model="woltConfigs.wolt_payment_type_id" />
                        </b-form-group>

                        <b-form-group :label="$t('wolt.map_delivery_to_product')">
                            <v-select :options="products" :clearable="false" label="name" :reduce="ele => ele.id" v-model="woltConfigs.wolt_delivery_product_id" />
                        </b-form-group>

                        <b-form-group :label="$t('wolt.map_service_fee_to_product')">
                            <v-select :options="products" :clearable="false" label="name" :reduce="ele => ele.id" v-model="woltConfigs.wolt_service_fee_product_id" />
                        </b-form-group>

                        <b-form-group :label="$t('wolt.map_delivery_extra_to_product')">
                            <v-select :options="products" :clearable="false" label="name" :reduce="ele => ele.id" v-model="woltConfigs.wolt_distance_extra_product_id" />
                        </b-form-group>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </validation-observer>
        <validation-observer ref="validationWoltOrders">
            <b-card class="my-1" no-body v-if="selected_business_unit">
                <b-card-header
                    class="d-flex justify-content-between align-items-center"
                    @click="isOrdersOpen = !isOrdersOpen"
                    aria-expanded="true"
                    aria-controls="accordion-2"
                >
                    <h4 class="card-title">
                        {{$t('wolt.orders')}}
                        <fa v-if="isOrdersInvalid" icon="exclamation-circle" class="text-danger ml-1"/>
                    </h4>
                    <fa
                        :icon="isOrdersOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                        class="ml-auto cursor-pointer"
                    ></fa>
                </b-card-header>
                <b-collapse id="accordion-2" v-model="isOrdersOpen" role="tabpanel" accordion="my-accordion">
                    <b-card-body>
                        <b-form-group :label="$t('wolt.cash_registers_with_wolt')">
                            <v-select :options="cashRegisters" multiple label="cash_register_id" :reduce="ele => ele.id" v-model="woltConfigs.cash_registers" />
                        </b-form-group>

                        <b-form-group :label="$t('wolt.order_notification')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.order_notification')"
                                rules="required"
                            >
                                <v-select :options="typeOfModal" label="name" :clearable="false" :reduce="ele => ele.id" v-model="woltConfigs.notification_type" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group :label="$t('wolt.order_notification_sound')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.order_notification_sound')"
                                rules="required"
                            >
                                <v-select :options="audioSounds" @close="playSound" label="name" :clearable="false" :reduce="ele => ele.id" v-model="woltConfigs.notification_sound" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group :label="$t('wolt.number_of_beeps')" v-if="woltConfigs.notification_sound === 'notification_printer_beep'">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.number_of_beeps')"
                                rules="required"
                            >
                                <b-form-input v-model="woltConfigs.number_of_beeps"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </validation-observer>
        <validation-observer ref="validationWoltRejectReasons">
            <b-card class="my-1" no-body v-if="selected_business_unit">
                <b-card-header
                    class="d-flex justify-content-between align-items-center"
                    @click="isRejectReasonsOpen = !isRejectReasonsOpen"
                    aria-expanded="true"
                    aria-controls="accordion-3"
                >
                    <h4 class="card-title">
                        {{$t('wolt.order_rejection_reasons')}}
                        <fa v-if="isRejectReasonsInvalid" icon="exclamation-circle" class="text-danger ml-1"/>
                    </h4>
                    <fa
                        :icon="isRejectReasonsOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                        class="ml-auto cursor-pointer"
                    ></fa>
                </b-card-header>
                <b-collapse id="accordion-3" v-model="isRejectReasonsOpen" role="tabpanel" accordion="my-accordion">
                    <b-card-body>
                        <b-form-group :label="$t('wolt.missing_ingredients')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.missing_ingredients')"
                                rules="required"
                            >
                                <b-form-input v-model="woltConfigs.reject_reason_missing_items"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group :label="$t('wolt.business_unit_closing_soon')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.business_unit_closing_soon')"
                                rules="required"
                            >
                                <b-form-input v-model="woltConfigs.reject_reason_venue_closing_soon"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group :label="$t('wolt.too_crowded')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.too_crowded')"
                                rules="required"
                            >
                                <b-form-input v-model="woltConfigs.reject_reason_too_busy"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </validation-observer>
        <validation-observer ref="validationWoltPrinting">
            <b-card class="my-1" no-body v-if="selected_business_unit">
                <b-card-header
                    class="d-flex justify-content-between align-items-center"
                    @click="isPrintingOpen = !isPrintingOpen"
                    aria-expanded="true"
                    aria-controls="accordion-4"
                >
                    <h4 class="card-title">
                        {{$t('wolt.order_printing')}}
                        <fa v-if="isPrintingInvalid" icon="exclamation-circle" class="text-danger ml-1"/>
                    </h4>
                    <fa
                        :icon="isPrintingOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                        class="ml-auto cursor-pointer"
                    ></fa>
                </b-card-header>
                <b-collapse id="accordion-4" v-model="isPrintingOpen" role="tabpanel" accordion="my-accordion">
                    <b-card-body>
                        <b-form-group :label="$t('wolt.order_location')">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('wolt.order_location')"
                                rules="required"
                            >
                                <v-select :options="printingLocationConfiguration" label="name" :clearable="false" :reduce="ele => ele.id" v-model="woltConfigs.printing_location_configuration" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-checkbox switch>{{$t('wolt.cash_register_that_accepted')}}</b-form-checkbox>

                        <b-form-group :label="$t('config.font')">
                            <validation-provider #default="{ errors }" :name="$t('config.font')" rules="">
                                <v-select v-model="woltConfigs.font" appendToBody :options="fonts" label="name" :reduce="name => name.id" :clearable="false">
                                    <template #option="{ name }">
                                        <div>{{ name }}</div>
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </validation-observer>
        <validation-observer ref="validationWoltOrderPrintConfig">
            <b-card class="my-1" no-body v-if="woltConfigs && woltConfigs.printed_order_config && $hasTierPermission(Tiers.Premium)">
                <b-card-header
                    class="d-flex justify-content-between align-items-center"
                    @click="isConfigOpen = !isConfigOpen"
                    aria-expanded="true"
                    aria-controls="accordion-4"
                >
                    <h4 class="card-title">
                        {{$t('config.printer_configs.order_printing_config')}}
                        <fa v-if="isOrderPrintingConfigInvalid" icon="exclamation-circle" class="text-danger ml-1"/>
                    </h4>
                    <fa
                        :icon="isConfigOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                        class="ml-auto cursor-pointer"
                    ></fa>
                </b-card-header>
                <b-collapse id="accordion-5" v-model="isConfigOpen" role="tabpanel" accordion="my-accordion">
                    <b-card-body>
                        <b-form-checkbox switch v-model="woltConfigs.printed_order_config.company_info">
                            {{$t('config.printer_configs.company_info')}}
                        </b-form-checkbox>
                        <b-form-checkbox switch v-model="woltConfigs.printed_order_config.timestamp">
                            {{$t('config.printer_configs.date_time')}}
                        </b-form-checkbox>
                        <b-form-checkbox switch v-model="woltConfigs.printed_order_config.employee">
                            {{$t('general.employees')}}
                        </b-form-checkbox>
                        <b-form-checkbox switch v-model="woltConfigs.printed_order_config.price_per_item">
                            {{$t('config.printer_configs.price_per_item')}}
                        </b-form-checkbox>
                        <b-form-checkbox switch v-model="woltConfigs.printed_order_config.total_price">
                            {{$t('config.printer_configs.total_price')}}
                        </b-form-checkbox>

                        <b-form-checkbox switch v-model="woltConfigs.printed_order_config.bigger_font_for_printed_order">
                            {{ $t('config.printer_configs.bigger_font_for_printed_order') }}
                        </b-form-checkbox>
                        <b-form-checkbox switch v-model="woltConfigs.printed_order_config.seperate_item">
                            {{ $t('config.printer_configs.separate_item') }}
                        </b-form-checkbox>
                        <b-form-checkbox switch v-model="woltConfigs.printed_order_config.beep_sound">
                            {{ $t('config.printer_configs.beep_sound') }}
                        </b-form-checkbox>

                        <b-form-group :label="$t('config.printer_configs.beep_sound_number')" class="mt-1" v-if="woltConfigs.printed_order_config.beep_sound">
                            <validation-provider class="mb-1" #default="{ errors }" :name="$t('config.printer_configs.beep_sound_number')" rules="required">
                                <b-form-input  v-model.number="woltConfigs.printed_order_config.beep_number" rules="required" :placeholder="$t('config.printer_configs.enter_beep_sound_number')">
                                </b-form-input>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group :label="$t('config.cash_registers.status.printer')" class="mt-1" v-if="woltConfigs.printed_order_config.beep_sound">
                            <validation-provider #default="{ errors }" :name="$t('config.cash_registers.status.printer')" rules="required">
                                <v-select v-model="woltConfigs.printed_order_config.printer_type" appendToBody :options="printerTypes" label="name" :reduce="name => name.id" :clearable="false">
                                    <template #option="{ name }">
                                        <div>{{ name }}</div>
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </validation-observer>
        <div class="d-flex justify-content-end mt-2">
            <b-button variant="primary" @click="validate">
                {{ $t('general.save') }}
            </b-button>
        </div>
    </b-overlay>
</template>

<script>
    import {BCard, BOverlay, BFormGroup, BFormInput, BButton, BFormCheckbox, BCollapse, BCardHeader, BCardBody} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import vSelect from 'vue-select'
    import Notification1 from '@/assets/sounds/Notification1.mp3'
    import Notification2 from '@/assets/sounds/Notification2.mp3'
    import Notification3 from '@/assets/sounds/Notification3.mp3'
    import Notification4 from '@/assets/sounds/Notification4.mp3'
    import Notification5 from '@/assets/sounds/Notification5.mp3'
    import Notification6 from '@/assets/sounds/Notification6.mp3'
    import {Tiers} from '@/libs/enums/Tiers'
    import {Font} from '@/libs/enums/Font'
    import {PrinterTypes} from '@/libs/enums/PrinterTypes'

    export default {
        components: {
            BCard,
            BOverlay,
            BFormGroup,
            BFormInput,
            BButton,
            BFormCheckbox,
            BCollapse,
            BCardHeader,
            BCardBody,
            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        computed: {
            Tiers() {
                return Tiers
            },
            fonts() {
                return [
                    {id: Font.FontA, name: 'Font A'},
                    {id: Font.FontB, name: 'Font B'}
                ]
            }
        },
        data() {
            return {
                printingLocationConfiguration: [
                    {
                        id: 'global_configuration',
                        name: 'Globalna konfiguracija (Upošteva nastavitve iz printerjev)'
                    },
                    {
                        id: 'cash_register_accepting_order',
                        name: 'Blagajna, ki sprejema naročilo '
                    }
                ],
                audioSounds: [
                    {
                        id: 'notification_1',
                        name: 'Obvestilo 1',
                        audio: new Audio(Notification1)
                    },
                    {
                        id: 'notification_2',
                        name: 'Obvestilo 2',
                        audio: new Audio(Notification2)
                    },
                    {
                        id: 'notification_3',
                        name: 'Obvestilo 3',
                        audio: new Audio(Notification3)
                    },
                    {
                        id: 'notification_4',
                        name: 'Obvestilo 4',
                        audio: new Audio(Notification4)
                    },
                    {
                        id: 'notification_5',
                        name: 'Obvestilo 5',
                        audio: new Audio(Notification5)
                    },
                    {
                        id: 'notification_6',
                        name: 'Obvestilo 6',
                        audio: new Audio(Notification6)
                    },
                    {
                        id: 'notification_printer_beep',
                        name: 'Pisk printerja na blagajnah, ki sprejemajo wolt narocila'
                    }

                ],
                showLoader: false,
                printerTypes: [
                    {id: PrinterTypes.Rongta, name: 'Rongta'},
                    {id: PrinterTypes.Citizen, name: 'Citizen'}
                ],
                woltConfigs: {
                    printed_order_config: {
                        company_info: false,
                        timestamp: false,
                        table: false,
                        employee: false,
                        price_per_item: false,
                        total_price: false,
                        seperate_item: false,
                        beep_sound: false,
                        beep_number:0,
                        printer_type:0

                    }
                },
                cashRegisters: [],
                selected_business_unit: null,
                allowedBusinessUnits: [],
                paymentTypes: [],
                products: [],
                typeOfModal: [
                    {
                        id: 'show_notification',
                        name: 'Prikaži obvestilo'
                    },
                    {
                        id: 'show_modal_confirmation',
                        name: 'Prikaži okno za potrditev'
                    }
                ],
                isDataOpen: true,
                isOrdersOpen: false,
                isRejectReasonsOpen: false,
                isPrintingOpen: false,
                isConfigOpen: false,
                isDataInvalid: false,
                isOrdersInvalid: false,
                isRejectReasonsInvalid: false,
                isPrintingInvalid: false,
                isOrderPrintingConfigInvalid: false
            }
        },
        methods: {
            playSound() {
                const element = this.audioSounds.find(sound => sound.id === this.woltConfigs.notification_sound)

                if (element && element.audio) {
                    element.audio.play()
                }
            },
            async loadWoltConfig() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get(`/api/client/v1/cash_registers/${this.selected_business_unit}`)
                    this.cashRegisters = response.data ?? []
                    const response2 = await this.$http.get(`/api/client/v1/wolt/config/${this.selected_business_unit}`)
                    this.woltConfigs = response2.data ?? {}
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async loadBusinessUnits() {
                try {
                    const response =  await this.$http.get('/api/client/v1/business_units')
                    this.allowedBusinessUnits = response.data ?? []
                    this.selected_business_unit = this.allowedBusinessUnits[0].id
                    await this.loadWoltConfig()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            },
            async loadProducts() {
                try {
                    const response =  await this.$http.get('/api/client/v1/products')
                    this.products = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            },
            async loadPaymentTypes() {
                this.dataLoaded = false
                try {
                    const response =  await this.$http.get('/api/client/v1/payment_types')
                    this.paymentTypes = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.dataLoaded = true
                }
            },
            async businessUnitChanged() {
                await this.loadWoltConfig()
            },
            validate() {
                this.isDataInvalid = false
                this.isOrdersInvalid = false
                this.isRejectReasonsInvalid = false
                this.isPrintingInvalid = false
                this.isOrderPrintingConfigInvalid = false

                Promise.all([
                    this.$refs.validationWoltData.validate().then((result) => {
                        if (!result) {
                            this.isDataInvalid = true
                        }
                    }),
                    this.$refs.validationWoltOrders.validate().then((result) => {
                        if (!result) {
                            this.isOrdersInvalid = true
                        }
                    }),
                    this.$refs.validationWoltRejectReasons.validate().then((result) => {
                        if (!result) {
                            this.isRejectReasonsInvalid = true
                        }
                    }),
                    this.$refs.validationWoltPrinting.validate().then((result) => {
                        if (!result) {
                            this.isPrintingInvalid = true
                        }
                    }),
                    this.$refs.validationWoltOrderPrintConfig.validate().then((result) => {
                        if (!result) {
                            this.isOrderPrintingConfigInvalid = true
                        }
                    })
                ]).then(() => {
                    if (!this.isDataInvalid && !this.isOrdersInvalid && !this.isRejectReasonsInvalid && !this.isPrintingInvalid && !this.isOrderPrintingConfigInvalid) {
                        this.saveConfig()
                    }
                })
            },
            async saveConfig() {
                try {
                    this.showLoader = true

                    await this.$http.post(`/api/client/v1/wolt/config/${this.selected_business_unit}`, this.woltConfigs)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            this.dataLoaded = false
            await this.loadPaymentTypes()
            await this.loadProducts()
            await this.loadBusinessUnits()
            this.dataLoaded = true

        }
    }
</script>

<style scoped>

</style>